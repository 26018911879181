const darkTheme = {
  paper: "#161a24",
  grid: "#242732",
  axis: "#31363e",
  crosshair: "#9598a0",
  font: "rgba(255,255,255, 0.87)",
  fontSec: "rgba(255,255,255, 0.56)",
  green: "#4da39a",
  red: "#e16052",
  // volume: "0,0,15",
  volume: "30,136,229", // rgb(30, 136, 229)
};
export default darkTheme;
