const lightTheme = {
  paper: "#fff",
  grid: "#f2f3f3",
  axis: "#e1e1e3",
  crosshair: "#9598a0",
  font: "rgba(0,0,0,0.87)",
  fontSec: "rgba(0,0,0,0.56)",
  green: "#4da39a",
  red: "#e16052",
  //volume: "180,180,200",
  volume: "30,136,229", // rgb(30, 136, 229)
};

export default lightTheme;
